import { configureStore } from '@reduxjs/toolkit';
import { homeownerApi } from 'services/api/homeownerApi';
import { prequalApi } from 'services/api/prequalApi';
import { smartyStreetsApi } from 'services/api/smartyStreetsApi';
import { underwriteApi } from 'services/api/underwriteApi';
import applicantReducer from 'store/applicant';
import prequalApplicationReducer from 'store/prequalApplication';
import propertyReducer from 'store/property';
import estimatesReducer from './estimates';
import generalReducer from './general';
import productApplicationReducer from './productApplication';
import productQuizReducer from './productQuiz';

const store = configureStore({
  reducer: {
    applicant: applicantReducer,
    property: propertyReducer,
    prequalApplication: prequalApplicationReducer,
    estimates: estimatesReducer,
    general: generalReducer,
    productQuiz: productQuizReducer,
    productApplication: productApplicationReducer,
    [homeownerApi.reducerPath]: homeownerApi.reducer,
    [prequalApi.reducerPath]: prequalApi.reducer,
    [smartyStreetsApi.reducerPath]: smartyStreetsApi.reducer,
    [underwriteApi.reducerPath]: underwriteApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      homeownerApi.middleware,
      prequalApi.middleware,
      smartyStreetsApi.middleware,
      underwriteApi.middleware,
    ]);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;

export default store;

import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Header, LinkButton, Modal, Size, SplashText, Style, directory } from '@pointdotcom/pds';
import ButtonScheduleNextAvailable from 'components/ButtonScheduleNextAvailable';
import ScheduleCalendar from 'components/ScheduleCalendar';
import { Availability, YCBMCal } from 'components/ScheduleCalendar/constants';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useCalendar } from 'containers/prequal/hooks/useCalendar';
import { dayjs } from 'lib/dayjs';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { Contact } from 'types';
import i18n from './i18n';
import {
  AppointmentTimeStyle,
  ChooseLinkStyle,
  ModifiedScheduleButtonStyle,
  ScheduleCallContainerStyle,
} from './styles';

interface ScheduleCallOverlayProps {
  isOpen: boolean;
  onModalClose: () => void;
  estimate: OfferEstimateModel;
  applicant: null | Contact;
  calendar?: YCBMCal;
  showTimeSelectorOnly?: boolean;
}

function AppointmentDetail({ availability }: { availability: null | Availability }) {
  if (availability == null) {
    return null;
  }

  const { timestamp, name } = availability;
  const dateTime = dayjs(parseInt(timestamp, 10));

  return (
    <AppointmentTimeStyle>
      {dateTime.format('dddd, MMMM Do')} at {dateTime.format('h:mma')} with {name}
    </AppointmentTimeStyle>
  );
}

export default function ScheduleCallOverlay({
  isOpen,
  onModalClose,
  estimate,
  applicant,
  calendar,
  showTimeSelectorOnly = false,
}: ScheduleCallOverlayProps) {
  const [showTimeSelectorFromState, setShowTimeSelector] = useState(false);
  const showTimeSelector = showTimeSelectorOnly || showTimeSelectorFromState;
  const navigate = useNavigate();

  const { calendarURL } = useCalendar({
    calendar,
    forStateAbbr: estimate.property?.address?.state,
  });

  // TODO: separate these into 2 different components for HEI and follow ups
  const scheduleSuccessRedirectUrl = generateUrlFromPage(Page.HEI_SCHEDULE_COMPLETE, {
    estimateKey: estimate.key,
  });

  // if something goes wrong with the redirection after scheduling, send them to the generic schedule success page
  const handleScheduleError = () => {
    window.location.assign(directory.pointLink.schedule_success);
  };

  const handleScheduleSuccess = ({ availability }: { availability: Availability }) => {
    const { email, timestamp: datetime } = availability;
    if (availability) {
      if (scheduleSuccessRedirectUrl.replace(/\//g, '').length > 0) {
        navigate({
          pathname: scheduleSuccessRedirectUrl,
          search: new URLSearchParams({
            email,
            datetime,
            estimateKey: estimate.key,
          }).toString(),
        });
      } else {
        handleScheduleError();
      }
    }
  };

  const contact: undefined | Contact = applicant
    ? {
        firstName: applicant.firstName ?? '',
        lastName: applicant.lastName ?? '',
        email: applicant.email ?? '',
        phone: applicant.phone ?? '',
      }
    : undefined;

  const buildIframeParams = () => {
    const { firstName, lastName, phone, email } = applicant ?? {};
    const params = {
      FNAME: firstName ?? '',
      LNAME: lastName ?? '',
      EMAIL: email ?? '',
      PHONENUMBER: phone ?? '',
      ESTIMATEID: estimate.key,
      team: 'any',
    };
    return new URLSearchParams(Object.entries(params)).toString();
  };

  const handleModalClose = () => {
    setShowTimeSelector(false);
    onModalClose();
  };

  return (
    <Modal isOpen={isOpen} onModalClose={handleModalClose} padContainer={false} shadeBg>
      <ScheduleCallContainerStyle>
        <Header styleSize={Size.Medium}>{i18n.scheduleATime}</Header>
        {!showTimeSelector && (
          <ModifiedScheduleButtonStyle>
            <SplashText italic>{i18n.ourNext}</SplashText>
            <ButtonScheduleNextAvailable
              apiDomain={process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT}
              onDifferentTimeLinkClick={() => setShowTimeSelector(true)}
              onSessionStorageError={handleScheduleError}
              onPostSchedule={handleScheduleSuccess}
              contact={contact}
              calendar={calendar}
              buttonProps={{
                styleType: Style.Primary,
              }}
              Description={AppointmentDetail}
            />
            <ChooseLinkStyle>
              <LinkButton onClick={() => setShowTimeSelector(true)}>
                {i18n.chooseADifferent}
              </LinkButton>
            </ChooseLinkStyle>
          </ModifiedScheduleButtonStyle>
        )}
        {showTimeSelector && (
          <ScheduleCalendar calendarURL={calendarURL} iframeParams={buildIframeParams()} />
        )}
      </ScheduleCallContainerStyle>
    </Modal>
  );
}

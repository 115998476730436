export const confirmDeleteFile = () => {
  // TODO: add a confirmation dialog
  // return window.confirm(i18n.areYouSure);
  return true;
};

export function getFilePreviewUrl(
  previewUrl: string,
  { width, height }: { width?: number; height?: number } = {}
) {
  const url = new URL(previewUrl);
  if (width != null) {
    url.searchParams.append('width', `${width}`);
  }
  if (height != null) {
    url.searchParams.append('height', `${height}`);
  }
  return url.toString();
}

import React, { useEffect } from 'react';
import { Dayjs } from 'dayjs';
import Helmet from 'react-helmet';
import {
  Button,
  DirectionAndPlacement,
  IconName,
  InputMask,
  InputMaskType,
  Logo,
  Size,
  Style,
  TemplatedText,
  directory,
} from '@pointdotcom/pds';
import { SCHEDULED_STORAGE_KEY } from 'components/ButtonScheduleNextAvailable';
import FullScreenLoading from 'components/FullScreenLoading';
import { navItems as footerNavItems } from 'components/MainFooter';
import PointCopyright from 'components/MainFooter/PointCopyright';
import ContactModal from 'components/SaveApplicantContactModal';
import { generateUrlFromPage, getURLParam, pages } from 'containers/helpers';
import { ModalPageStyle } from 'containers/prequal/ModalPage/styles';
import { useHistory, useLocation, useParams } from 'containers/routerHelpers';
import { dayjs } from 'lib/dayjs';
import ApplicantModel from 'models/ApplicantModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import AvailabilityModel from 'models/prequal/availability';
import { getApplicantModel } from 'store/applicant';
import { Products, productFullName } from 'store/constants';
import { getEstimatesAreLoading, getHEIEstimateModel } from 'store/estimates';
import { setSaveApplicantContactModalOpen } from 'store/general';
import { useDispatch, useSelector } from 'store/hooks';
import { getEstimate } from 'store/thunks/estimates';
import * as appStyles from 'styles/';
import i18n from './i18n';
import * as styles from './styles';

// NOTE: For YCBM redirects to this page, afterbooking URL should be -
// [endpoint]/schedule-complete?email={TEAM-EMAIL}&phone={PHONENUMBER}&datetime={START-BOOKER-ISO-8601}&estimateid={ESTIMATEID}

const P = appStyles.SplashCopySerifStyle; // Naming convenience;

const HeaderBlock = ({ dateTimeObj }: { dateTimeObj: Dayjs | null }) => {
  let preHeader;
  let headerText = i18n.youreNowNoData;
  if (dateTimeObj) {
    headerText = i18n.dateAtTime;
    preHeader = i18n.youreNowPreHeader;
  }

  return (
    <styles.ScheduleHeaderStyle
      styleSize={Size.Large}
      styleAlign={DirectionAndPlacement.Left}
      styleAlignMobile={DirectionAndPlacement.Left}
      preHeader={preHeader}
      maxWidth="450px"
    >
      <TemplatedText
        values={{
          date: () => {
            return (
              <>
                {dateTimeObj?.format('dddd MMMM Do')}
                <br />
              </>
            );
          },
          time: dateTimeObj?.format('h:mm a'),
        }}
      >
        {headerText}
      </TemplatedText>
    </styles.ScheduleHeaderStyle>
  );
};

const Footer = () => {
  return (
    <styles.FooterStyle>
      <Logo styleType={Style.MonoLight} />
      <nav>
        {footerNavItems.map((item) => (
          <a
            href={Object.keys(item).map((key) => item[key])[0]}
            key={Object.keys(item)[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Object.keys(item)[0]}
          </a>
        ))}
      </nav>
    </styles.FooterStyle>
  );
};

interface BodyBlockProps {
  product: Products;
  phoneFormatted: string;
  estimate: OfferEstimateModel | null;
}

const BodyBlockDefault = ({ product, phoneFormatted }: Omit<BodyBlockProps, 'estimate'>) => {
  const bodyText = [];

  if (phoneFormatted) {
    bodyText.push(i18n.weWillCallYouAtPhone);
  }

  bodyText.push(i18n.wereGoing);

  return (
    <TemplatedText
      values={{
        phone: phoneFormatted,
        aOrAnProductName: `${product === Products.HEI ? i18n.an : i18n.a} ${
          productFullName[product]
        }`,
      }}
    >
      {bodyText.join(' ')}
    </TemplatedText>
  );
};

const BodyBlockWithApplication = ({
  phoneFormatted,
}: Omit<BodyBlockProps, 'product' | 'estimate'>) => {
  const bodyText = [];

  bodyText.push(i18n.duringTheCall);

  return (
    <TemplatedText
      values={{
        phone: phoneFormatted,
      }}
    >
      {bodyText.join(' ')}
    </TemplatedText>
  );
};

const BodyBlock = ({ product, phoneFormatted, estimate }: BodyBlockProps) => {
  if (estimate) {
    return (
      <P>
        <BodyBlockWithApplication phoneFormatted={phoneFormatted} />
      </P>
    );
  }

  return (
    <P>
      <BodyBlockDefault product={product} phoneFormatted={phoneFormatted} />
    </P>
  );
};

const LastBlockWithHEIBrochureLink = () => {
  return (
    <>
      <P>
        <TemplatedText
          values={{
            brochureLinkText: (
              <a href={directory.pointLink.brochure} target="_blank" rel="noopener noreferrer">
                {i18n.brochureLinkText}
              </a>
            ),
          }}
        >
          {i18n.inTheMeantime}
        </TemplatedText>
      </P>
      <P>{i18n.lookingForward}</P>
    </>
  );
};

const LastBlockWithStartApplicationCTA = ({ estimate }: { estimate: OfferEstimateModel }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    if (!estimate.getApplicant()?.getIsComplete()) {
      dispatch(setSaveApplicantContactModalOpen(true));
      return;
    }

    const applicationPage = generateUrlFromPage(pages.HEI_APPLICATION, {
      estimateKey: estimate.key,
    });
    history.push(applicationPage);
  };

  return (
    <styles.CTASection>
      <Button
        styleSize={Size.Large}
        styleAlign={DirectionAndPlacement.Right}
        mobileBlock
        iconType={IconName.ChevronRight}
        onClick={handleClick}
      >
        {i18n.startApplication}
      </Button>
    </styles.CTASection>
  );
};

interface LastBlockProps {
  product: Products;
  estimate: OfferEstimateModel | null;
}

const LastBlock = ({ product, estimate }: LastBlockProps) => {
  if (estimate) {
    return <LastBlockWithStartApplicationCTA estimate={estimate} />;
  }
  if (product === Products.HEI) {
    return <LastBlockWithHEIBrochureLink />;
  }
  return <P>{i18n.lookingForward}</P>;
};

interface ScheduleCompletePageProps {
  applicant?: ApplicantModel | null;
  product?: Products;
}

const ScheduleCompletePage = ({
  applicant: applicantFromProps,
  product = Products.HEI,
}: ScheduleCompletePageProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicantFromStore = useSelector(getApplicantModel);
  const applicant = applicantFromStore || applicantFromProps;
  const estimate = useSelector(getHEIEstimateModel);
  const estimateLoading = useSelector(getEstimatesAreLoading);
  const params = useParams();
  const location = useLocation();
  const scheduledAvailability: TSFixMe =
    AvailabilityModel.getFromSessionStorage(SCHEDULED_STORAGE_KEY);
  const estimateKey =
    params?.estimateKey ||
    getURLParam('estimateid', location) ||
    getURLParam('estimateKey', location);
  const phone = getURLParam('phone', location) || applicant.phone;
  const dateTime = getURLParam('datetime', location);

  let time: string | number | null = scheduledAvailability
    ? parseInt(scheduledAvailability.timestamp, 10)
    : null;
  if (dateTime) {
    time = dateTime;
  }

  // accept numerical timestamps
  if (time && typeof time === 'string' && time.length && /^[0-9]*$/.test(time)) {
    time = parseInt(time, 10);
  }

  const dateTimeObj = time && dayjs(time).isValid() ? dayjs(time) : null;
  const phoneFormatted = phone
    ? new InputMask({ type: InputMaskType.Phone }).getFormatted(phone)
    : null;

  // get the hei estimate if there is an estimate id in the URL
  useEffect(() => {
    if (!estimateKey || estimate) {
      return;
    }
    dispatch(getEstimate({ estimateKey }));
  }, [dispatch, estimateKey, estimate]);

  if (estimateLoading) {
    return <FullScreenLoading />;
  }

  const handleApplicantSave = () => {
    const applicationPage = generateUrlFromPage(pages.HEI_APPLICATION, {
      estimateKey: estimate?.key,
    });
    history.push(applicationPage);
  };

  return (
    <>
      <styles.ScheduleCompletePageStyle>
        <styles.ScheduleCompleteBodyStyle />
        <Helmet title={i18n.heiPageTitle} />
        <ModalPageStyle>
          <styles.SchedulePageContainerStyle>
            <main>
              <HeaderBlock dateTimeObj={dateTimeObj} />
              <BodyBlock product={product} phoneFormatted={phoneFormatted} estimate={estimate} />
              <LastBlock estimate={estimate} product={product} />
            </main>
            <Footer />
            <PointCopyright />
          </styles.SchedulePageContainerStyle>
        </ModalPageStyle>
      </styles.ScheduleCompletePageStyle>
      <ContactModal applicant={applicant} onSubmitValid={handleApplicantSave} />
    </>
  );
};

export default ScheduleCompletePage;

import React, { useEffect, useRef, useState } from 'react';
import nullthrows from 'nullthrows';
import smoothscroll from 'smoothscroll-polyfill';
import {
  Button,
  ButtonClickFunc,
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Input,
  InputChangeEvent,
  InputMask,
  InputMaskFormatter,
  InputMaskType,
  MaskEventType,
  ResponsiveText,
  Size,
  Style,
  TemplatedText,
  useIsMobile,
} from '@pointdotcom/pds';
import HeiOfferEstimatorPageCapModal from 'containers/hei/HeiOfferEstimatorPage/HeiOfferEstimatorPageCapModal';
import { captureProperties } from 'lib/posthogEvents';
import { ShareType } from 'models/BaseEstimateModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { currencyMask } from 'models/helpers';
import { Products } from 'store/constants';
import { HeaderDescriptionStyle } from '../styles';
import CapShieldIcon from './CapShieldIcon';
import ScenariosTableCapTip from './ScenariosTableCapTip';
import i18n from './i18n';
import scenarios, { Scenario, ScenarioType, scenarioOrder } from './scenarios';
import * as styles from './styles';

const percMask = new InputMask({
  type: InputMaskType.Number,
  options: { format: InputMaskFormatter.Percentage, precision: 1 },
});

smoothscroll.polyfill();

enum TableColumn {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Scenario = 'scenario',
  HomeValue = 'homeValue',
  YourShare = 'yourShare',
  Repayment = 'repayment',
  EquivApr = 'equivApr',
}

// NOTE: changeing the order here will determine order for whole table
const tableHeaderText = {
  [TableColumn.Scenario]: (
    <ResponsiveText mobileText={i18n.homeAppreciationPlural}>
      {i18n.homeAppreciation}
    </ResponsiveText>
  ),
  [TableColumn.HomeValue]: i18n.finalHomeValue,
  [TableColumn.YourShare]: (
    <>
      {i18n.yourShare}
      <sup>1</sup>
    </>
  ),
  [TableColumn.Repayment]: i18n.yourTotalRepayment,
  [TableColumn.EquivApr]: (
    <>
      {i18n.equivalentAPR}
      <sup>2</sup>
    </>
  ),
};

const TableFooter = () => (
  <styles.ScenarioTableFooterStyle data-testid="ScenarioTableFooterStyle">
    <Container>
      <ol>
        <li>{i18n.doesNotReflect}</li>
        <li>{i18n.doesNotHaveInterest}</li>
      </ol>
    </Container>
  </styles.ScenarioTableFooterStyle>
);

type TableHeaderProps = React.ComponentProps<typeof styles.TableHeaderStyle>;

const TableHeader = ({ collapsed }: TableHeaderProps) => (
  <styles.TableHeaderStyle collapsed={collapsed} data-testid="TableHeaderStyle">
    <Container>
      <styles.ScenarioHeaderItemContentStyle collapsed={collapsed}>
        <styles.ScenarioHeaderHeaderColumnStyle>
          {tableHeaderText.scenario}
        </styles.ScenarioHeaderHeaderColumnStyle>
        <styles.ScenarioCellsHeaderSectionStyle>
          {Object.values(TableColumn)
            .filter((key) => key !== 'scenario')
            .map((headerTextKey) => (
              <styles.ScenarioHeaderCellStyle key={headerTextKey}>
                <span>{tableHeaderText[headerTextKey]}</span>
              </styles.ScenarioHeaderCellStyle>
            ))}
          <styles.ScenarioCTACellStyle> </styles.ScenarioCTACellStyle>
        </styles.ScenarioCellsHeaderSectionStyle>
      </styles.ScenarioHeaderItemContentStyle>
    </Container>
  </styles.TableHeaderStyle>
);

interface CustomInputProps {
  onChange: InputChangeEvent;
  value?: string;
  _ref: React.Ref<null | HTMLInputElement>;
}

const CustomInput = ({ onChange, value = '', _ref }: CustomInputProps) => {
  const hasInputValue = !Number.isNaN(parseFloat(value));

  return (
    <styles.CustomInputFormStyle
      hasValue={hasInputValue}
      noValidate
      onSubmit={(e) => e.preventDefault()}
    >
      <Input
        aria-label={i18n.enterAHome}
        incrementable
        inputMode="numeric"
        mask={InputMaskType.Number}
        maskOptions={{ format: InputMaskFormatter.Percentage, precision: 1 }}
        controlStyleType={Style.Default}
        onChange={onChange}
        placeholder=""
        value={value}
        min={-99}
        max={99}
        noMargin
        ref={_ref}
        {...({ size: 6 } as TSFixMe)}
        styleType={Style.Mono}
        styleAlign={DirectionAndPlacement.Center}
        styleSize={Size.Small}
        maskOnEvent={MaskEventType.Blur}
      />
    </styles.CustomInputFormStyle>
  );
};

interface CustomInputWithLabelProps {
  inputValue: string;
  scenarioLabel: string;
  onInputChange: InputChangeEvent;
  _ref: React.Ref<null | HTMLInputElement>;
  percLabelSm: string;
  percLabel: string;
}

function CustomInputWithLabel({
  inputValue,
  scenarioLabel,
  onInputChange,
  _ref,
  percLabelSm,
  percLabel,
}: CustomInputWithLabelProps): JSX.Element {
  const handleSuppressClick: React.MouseEventHandler = (e) => {
    // prevents page zooming on mobile
    const parent = document.getElementsByClassName('InputContainerStyle')[0];
    if (e && e.target && (parent as TSFixMe).length > 0 && parent.contains(e.target as TSFixMe)) {
      e.preventDefault();
    }
  };

  const hasInputValue = !Number.isNaN(parseFloat(inputValue));
  return (
    <styles.HeaderContentCustomStyle onClick={handleSuppressClick}>
      {!hasInputValue && <header> {scenarioLabel} </header>}
      <CustomInput onChange={onInputChange} value={inputValue} _ref={_ref} />
      {!hasInputValue && <styles.PercSignStyle>%</styles.PercSignStyle>}
      {hasInputValue && (
        <ResponsiveText mobileText={percLabelSm} breakpoint="350px">
          {percLabel}
        </ResponsiveText>
      )}
    </styles.HeaderContentCustomStyle>
  );
}

const ScenarioCellsCustomPrompt = () => (
  <styles.ScenarioCellsCustomInputStyle>
    <div>
      <Icon name={IconName.ChevronLeft} styleType={Style.Subtle} strokeSize={0} />
      <aside>{i18n.enterAHome}</aside>
    </div>
  </styles.ScenarioCellsCustomInputStyle>
);

type ScenarioCellValue = {
  header: React.ReactNode;
  value: string;
  capDifference?: null | number;
};

interface ScenarioCellProps {
  cellItem?: ScenarioCellValue;
  estimate: OfferEstimateModel;
  tooltipHangsDown?: boolean;
  showCapTip?: null | boolean;
  onToggleCapTip?: () => void;
}

function ScenarioCell({
  cellItem,
  estimate,
  tooltipHangsDown = false,
  showCapTip = null,
  onToggleCapTip,
}: ScenarioCellProps) {
  const [capModalIsOpen, setCapModalIsOpen] = useState<boolean>(false);

  const handleKeypress: React.KeyboardEventHandler = (event) => {
    if (event.code === 'Enter' && onToggleCapTip) {
      event.preventDefault();
      event.stopPropagation();
      onToggleCapTip();
    }
  };

  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onToggleCapTip?.();
  };

  const isClickable = onToggleCapTip != null && cellItem?.capDifference != null;

  if (!cellItem) {
    return null;
  }

  return (
    <styles.ScenarioCellStyle
      capTipIsShown={showCapTip}
      tabIndex={isClickable ? 0 : undefined}
      onClick={isClickable ? handleClick : undefined}
      onKeyDown={isClickable ? handleKeypress : undefined}
      tooltipHangsDown={tooltipHangsDown}
    >
      <aside>{cellItem.header}</aside>
      <styles.ScenarioCellValueStyle>
        {cellItem.capDifference != null && (
          <CapShieldIcon>
            <ScenariosTableCapTip
              capDifference={cellItem.capDifference}
              onClickCapLink={() => setCapModalIsOpen(true)}
            />
            <HeiOfferEstimatorPageCapModal
              estimate={estimate}
              isOpen={capModalIsOpen}
              onModalClose={() => setCapModalIsOpen(false)}
            />
          </CapShieldIcon>
        )}
        {cellItem.value}
      </styles.ScenarioCellValueStyle>
    </styles.ScenarioCellStyle>
  );
}

interface ScenarioCellsProps {
  scenarioKey: ScenarioType;
  onCalcClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    options: { scenario: ScenarioType }
  ) => unknown;
  selectedYear: string | number;
  estimate: null | OfferEstimateModel;
  customScenarioPerc: string;
  tooltipHangsDown?: boolean;
  showCapTip?: null | boolean;
  onToggleCapTip?: () => void;
}

function ScenarioCells({
  scenarioKey,
  onCalcClick,
  selectedYear,
  estimate,
  customScenarioPerc,
  tooltipHangsDown,
  showCapTip,
  onToggleCapTip,
}: ScenarioCellsProps): null | JSX.Element {
  if (!estimate) {
    return null;
  }

  const handleCalcClick: ButtonClickFunc = (e) => {
    onCalcClick(e, { scenario: scenarioKey });
  };
  const scenario = scenarioKey;
  const selectedYearInt = parseInt(selectedYear as TSFixMe, 10);

  // We only calculate _annual_ home value increase in years for increase in home value. For declines in home
  // value, we look at the percentage change as a whole (so the years will default to 1 in this case)
  const conditionalYear = scenario === ScenarioType.DECLINE ? 1 : selectedYearInt;

  const scenarioPerc =
    scenario === ScenarioType.CUSTOM
      ? parseFloat(customScenarioPerc)
      : nullthrows(scenarios[scenario].appreciationPerc);
  const scenarioPercDec = scenarioPerc / 100;
  const finalHomeValue = estimate.getHomeValueScenario({
    appreciationPerc: scenarioPercDec,
    durationInYears: conditionalYear,
  });
  const finalHomeValueFormatted: string = currencyMask.getFormatted(finalHomeValue);
  const homeOwnerShareFormatted = estimate.getFormattedHomeOwnerShare({
    appreciationPerc: scenarioPercDec,
    durationInYears: conditionalYear,
    homeValue: finalHomeValue,
  });

  const pointsShareWithOfferAmount = estimate.getHomeownerRepaymentBase({
    lessTheOfferAmount: false,
    appreciationPerc: scenarioPercDec,
    durationInYears: selectedYearInt,
    homeValue: finalHomeValue,
  });

  const capDifference =
    estimate.getHomeownerRepaymentBase({
      lessTheOfferAmount: false,
      appreciationPerc: scenarioPercDec,
      durationInYears: selectedYearInt,
      shareType: ShareType.AppreciationBased,
      homeValue: finalHomeValue,
    }) - pointsShareWithOfferAmount || null;

  const pointsShareFormatted: string = currencyMask.getFormatted(pointsShareWithOfferAmount);

  const equivInterestFormatted = estimate.getFormattedEquivAPR({
    appreciationPerc: scenarioPercDec,
    durationInYears: selectedYearInt,
    pointsShareCustom: pointsShareWithOfferAmount,
  });

  const cellValuesMap: Partial<Record<TableColumn, ScenarioCellValue>> = {
    [TableColumn.HomeValue]: {
      // final home value
      header: tableHeaderText.homeValue,
      value: finalHomeValueFormatted,
    },
    [TableColumn.YourShare]: {
      // You keep
      header: tableHeaderText.yourShare,
      value: homeOwnerShareFormatted,
    },
    [TableColumn.Repayment]: {
      // point gets
      header: tableHeaderText.repayment,
      value: pointsShareFormatted,
      capDifference,
    },
    [TableColumn.EquivApr]: {
      // Equivalent APR
      header: tableHeaderText.equivApr,
      value: equivInterestFormatted,
    },
  };

  return (
    <styles.ScenarioCellsSectionStyle>
      {Object.values(TableColumn).map((key) => (
        <ScenarioCell
          key={key}
          cellItem={cellValuesMap[key]}
          estimate={estimate}
          tooltipHangsDown={tooltipHangsDown}
          showCapTip={showCapTip}
          onToggleCapTip={onToggleCapTip}
        />
      ))}
      <styles.ScenarioCTACellStyle>
        <Button
          styleAlign={DirectionAndPlacement.Center}
          styleType={Style.Dark}
          styleSize={Size.Small}
          onClick={handleCalcClick}
          gaTrackingId={`SeeTheMath${scenarioKey.charAt(0).toUpperCase() + scenarioKey.slice(1)}`}
        >
          {i18n.seeTheMath}
        </Button>
      </styles.ScenarioCTACellStyle>
    </styles.ScenarioCellsSectionStyle>
  );
}

interface ScenarioItemProps {
  customScenarioPerc: string;
  setCustomScenarioPerc: (newValue: string) => unknown;
  scenario: Scenario;
  scenarioKey: ScenarioType;
  onCalcClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    options: { scenario: ScenarioType }
  ) => unknown;
  estimate: OfferEstimateModel;
  product: Products;
  getBuyBackTime: () => string | number;
  isLastRow?: boolean;
  showCapTip?: null | boolean;
  onToggleCapTip?: () => void;
}

const ScenarioItem = ({
  customScenarioPerc,
  setCustomScenarioPerc,
  scenario,
  scenarioKey,
  onCalcClick,
  estimate,
  product,
  getBuyBackTime,
  isLastRow,
  showCapTip,
  onToggleCapTip,
}: ScenarioItemProps) => {
  const customInputRef = useRef<null | HTMLInputElement>(null);

  const handleCustomInputChange: InputChangeEvent = (e, { value }) => {
    setCustomScenarioPerc(value);
  };

  const selectedYear = getBuyBackTime();
  const scenarioLabel = scenario.label;
  const { percLabel } = scenario;
  const { percLabelSm } = scenario;
  const hasInputValue = !Number.isNaN(parseFloat(customScenarioPerc));
  const ContentComponentStyle =
    scenarioKey === ScenarioType.CUSTOM
      ? styles.ScenarioItemCustomContentStyle
      : styles.ScenarioItemContentStyle;
  let { appreciationPerc } = scenario;

  if (scenarioKey === ScenarioType.CUSTOM || appreciationPerc == null) {
    appreciationPerc = parseFloat(customScenarioPerc);
  }

  if (scenarioKey === ScenarioType.DECLINE) {
    appreciationPerc = Math.abs(appreciationPerc);
  }

  return (
    <styles.ScenarioItemStyle
      product={product}
      scenario={scenarioKey}
      data-testid={`scenario-${scenarioKey}`}
      {...captureProperties({ table_row: scenarioKey })}
    >
      <Container>
        <ContentComponentStyle hasCustomInputVal={hasInputValue}>
          <styles.ScenarioHeaderColumnStyle scenario={scenarioKey} product={product}>
            <div>
              {scenarioKey === ScenarioType.CUSTOM ? (
                <CustomInputWithLabel
                  onInputChange={handleCustomInputChange}
                  inputValue={customScenarioPerc}
                  _ref={customInputRef}
                  scenarioLabel={scenarioLabel}
                  percLabel={percLabel}
                  percLabelSm={percLabelSm}
                />
              ) : (
                <styles.HeaderContentScenarioLabelStyle>
                  {scenarioLabel}
                  <span>
                    {percMask.getFormatted(appreciationPerc)} {percLabel}
                  </span>
                </styles.HeaderContentScenarioLabelStyle>
              )}

              <styles.HeaderContentOverStyle>
                <styles.PercNumberStyle>
                  {' '}
                  {percMask.getFormatted(appreciationPerc)}
                </styles.PercNumberStyle>
                <styles.PercLabelStyle>
                  <ResponsiveText mobileText={percLabelSm} breakpoint="350px">
                    {percLabel}
                  </ResponsiveText>
                </styles.PercLabelStyle>
              </styles.HeaderContentOverStyle>

              {scenarioKey !== ScenarioType.CUSTOM && <styles.TableTicksStyle />}
            </div>
          </styles.ScenarioHeaderColumnStyle>
          {scenarioKey === ScenarioType.CUSTOM && !hasInputValue ? (
            <ScenarioCellsCustomPrompt />
          ) : (
            <ScenarioCells
              estimate={estimate}
              selectedYear={selectedYear}
              scenarioKey={scenarioKey}
              onCalcClick={onCalcClick}
              customScenarioPerc={customScenarioPerc}
              tooltipHangsDown={!isLastRow}
              showCapTip={showCapTip}
              onToggleCapTip={onToggleCapTip}
            />
          )}
        </ContentComponentStyle>
      </Container>
    </styles.ScenarioItemStyle>
  );
};

interface ScenariosTableProps {
  estimate: OfferEstimateModel;
  onCalcClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
    options: { scenario: ScenarioType }
  ) => unknown;
  preTableHeaderText?: null | string;
  product: Products;
  customScenarioPerc: string;
  setCustomScenarioPerc: (newValue: string) => unknown;
  getBuyBackTime: () => string | number;
}

export default function ScenariosTable({
  estimate,
  onCalcClick = () => undefined,
  preTableHeaderText,
  product,
  customScenarioPerc,
  setCustomScenarioPerc,
  getBuyBackTime,
}: ScenariosTableProps): JSX.Element {
  const scrollElmContainer = useRef<null | HTMLDivElement>(null);
  const { isMobile } = useIsMobile();
  const yearFormatted = new Date().getFullYear() + parseInt(getBuyBackTime() as TSFixMe, 10);

  const [capTipScenario, setCapTipScenario] = useState<null | ScenarioType>(null);

  const handleToggleCapTip = (scenarioKey: ScenarioType) => {
    setCapTipScenario((currentScenario) => (scenarioKey === currentScenario ? null : scenarioKey));
  };

  const handleClick: React.MouseEventHandler = () => {
    setCapTipScenario(null);
  };

  useEffect(() => {
    const scrollElementContainer = scrollElmContainer.current;

    if (!scrollElementContainer) {
      return;
    }

    if (scrollElementContainer.scrollLeft !== 0 && !isMobile) {
      scrollElementContainer.scrollTo(0, 0);
    }
  }, [isMobile]);

  return (
    <>
      <styles.HeaderDescInputPrintContainerStyle onClick={handleClick}>
        <HeaderDescriptionStyle>
          <Header styleAlign={DirectionAndPlacement.Left}>
            <TemplatedText
              values={{
                amount: estimate.getPricing()?.getFormattedOptionInvestmentAmount(),
                year: yearFormatted,
              }}
            >
              {
                estimate
                  ? i18n.homeAppreciationScenariosWithAmount
                  : i18n.homeAppreciationScenarios /* FORK */
              }
            </TemplatedText>
          </Header>
        </HeaderDescriptionStyle>
      </styles.HeaderDescInputPrintContainerStyle>
      {preTableHeaderText && (
        <styles.TablePreHeaderStyle>
          <Container>{preTableHeaderText}</Container>
        </styles.TablePreHeaderStyle>
      )}
      <styles.ScenariosStyle onClick={handleClick}>
        <TableHeader />
        <styles.ScenarioItemsContainerStyle
          ref={scrollElmContainer}
          data-testid="ScenarioItemsContainerStyle"
        >
          {scenarioOrder.map((scenarioKey, index) => (
            <ScenarioItem
              key={scenarioKey}
              estimate={estimate}
              scenarioKey={scenarioKey}
              scenario={scenarios[scenarioKey]}
              onCalcClick={onCalcClick}
              product={product}
              customScenarioPerc={customScenarioPerc}
              setCustomScenarioPerc={setCustomScenarioPerc}
              getBuyBackTime={getBuyBackTime}
              isLastRow={index === scenarioOrder.length - 1}
              showCapTip={capTipScenario == null ? null : capTipScenario === scenarioKey}
              onToggleCapTip={() => handleToggleCapTip(scenarioKey)}
            />
          ))}
        </styles.ScenarioItemsContainerStyle>
        <TableFooter />
      </styles.ScenariosStyle>
    </>
  );
}

export { TableHeader as ScenariosTableHeader };

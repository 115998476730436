import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { templatedString } from '@pointdotcom/pds';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { getUnderwriteFollowUpUrl } from 'services/underwrite';
import { SubscribableValue, waitForSubscribable } from './awaitableSubscription';
import i18n from './i18n';
import { useApplicationProcessingOutcome } from './useApplicationProcessingOutcome';

function promiseWithTimeout<T>(promise: PromiseLike<T>, timeoutMs: number): Promise<undefined | T> {
  return Promise.race([
    promise,
    new Promise<undefined>((resolve) => {
      setTimeout(() => resolve(undefined), timeoutMs);
    }),
  ]);
}

export function useHandleApplicationSubmitted({
  estimateKey,
}: {
  estimateKey: undefined | string;
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  const applicationProcessingOutcome = useApplicationProcessingOutcome();
  const applicationProcessingOutcomeSubscribable = useRef(
    new SubscribableValue(applicationProcessingOutcome)
  ).current;
  useEffect(() => {
    applicationProcessingOutcomeSubscribable.value = applicationProcessingOutcome;
  }, [applicationProcessingOutcome, applicationProcessingOutcomeSubscribable]);

  const navigate = useNavigate();

  const postAppDocUploadFollowupFlagEnabled =
    useFeatureFlag(FeatureFlag.PostAppDocUploadFollowup) ?? false;

  const showTaskCompletionModal = useTaskCompletionModal();

  const handleApplicationSubmitted = useCallback(async () => {
    try {
      setIsProcessing(true);
      if (postAppDocUploadFollowupFlagEnabled) {
        const outcome = await promiseWithTimeout(
          waitForSubscribable(
            applicationProcessingOutcomeSubscribable,
            (newOutcome) => !newOutcome.isProcessing
          ),
          60_000
        );
        if (outcome == null) {
          // If timed out, just redirect to dashboard:
          navigate(generateUrlFromPage(Page.DASHBOARD));
          return;
        }
        const { showDashboardModal, applicationTask, dashboard, navigateTo } = outcome;
        if (showDashboardModal) {
          // Show task completion modal
          const name = dashboard?.getContact().firstName;
          showTaskCompletionModal({
            task: applicationTask,
            title:
              name != null
                ? templatedString({ template: i18n.congratsWithName, values: { name } })
                : i18n.congrats,
            heading: i18n.applicationReceived,
          });
        }
        navigate(navigateTo ?? generateUrlFromPage(Page.DASHBOARD));
      } else {
        // Redirect to post-application document upload page on Underwrite
        if (estimateKey != null) {
          const underwriteFollowupUrl = getUnderwriteFollowUpUrl(estimateKey);
          window.location.assign(underwriteFollowupUrl);
          return;
        } else {
          navigate(generateUrlFromPage(Page.DASHBOARD));
        }
      }
    } finally {
      setIsProcessing(false);
    }
  }, [
    applicationProcessingOutcomeSubscribable,
    estimateKey,
    navigate,
    postAppDocUploadFollowupFlagEnabled,
    showTaskCompletionModal,
  ]);

  return { handleApplicationSubmitted, isProcessing };
}

import React from 'react';
import { BrowserRouter } from 'react-router';
import { Devtools } from 'components/Devtools';
import { FeatureFlagsProvider } from 'lib/featureFlags';
import routes from './routes';

const App = () => (
  <FeatureFlagsProvider>
    <BrowserRouter>{routes}</BrowserRouter>
    <Devtools />
  </FeatureFlagsProvider>
);

export default App;

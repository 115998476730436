import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { History } from 'history';
import {
  Navigate,
  Navigator,
  Route,
  RouteProps,
  Router,
  Routes,
  RoutesProps,
  To,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { Products } from 'store/constants';
import { generateUrl } from './helpers';
import { ProductContext, ProductProvider } from './prequal/hooks/ProductProvider';

export { useLocation, useParams };

export interface HistoryNavigation {
  push: (to: To) => void;
  replace: (to: To) => void;
}

export function useHistory(): HistoryNavigation {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      push: (to) => navigate(to),
      replace: (to) => navigate(to, { replace: true }),
    }),
    [navigate]
  );
}

interface RedirectProps {
  to: To;
  push?: boolean;
}

export function Redirect({ to, push = false }: RedirectProps): JSX.Element {
  return <Navigate to={to} replace={!push} />;
}

export function RedirectWithParams({ to: toFromProps, push = false }: RedirectProps): JSX.Element {
  const params = useParams();
  const product = useContext(ProductContext);

  const resolvedTo: To = useMemo(() => {
    if (typeof toFromProps === 'string') {
      return generateUrl(toFromProps, { ...params, product });
    }
    if (toFromProps.pathname != null) {
      return {
        ...toFromProps,
        pathname: generateUrl(toFromProps.pathname, { ...params, product }),
      };
    }
    return toFromProps;
  }, [params, product, toFromProps]);

  return <Navigate to={resolvedTo} replace={!push} />;
}

interface HistoryRouterProps {
  children: React.ReactNode;
  history: History;
}

const simplerMockedHistory = process.env.NODE_ENV === 'test';

function simplifyHistoryTo(to: To): To {
  if (typeof to === 'string') {
    return to;
  }

  if (to.hash != null && to.hash !== '') {
    return to;
  }
  if (to.search != null && to.search !== '') {
    return { pathname: to.pathname, search: to.search };
  }
  if (to.pathname != null) {
    return to.pathname;
  }

  return {};
}

export function HistoryRouter({ children, history }: HistoryRouterProps): JSX.Element {
  const [location, setLocation] = useState(history.location);
  useLayoutEffect(() => {
    setLocation(history.location);
    return history.listen(() => setLocation(history.location));
  }, [history]);

  const navigator = useMemo((): Navigator => {
    if (!simplerMockedHistory) {
      return history;
    }

    return {
      createHref: (to) => history.createHref(to),
      go: (delta) => history.go(delta),
      push: (to: To, state?: unknown) => {
        if (state != null) {
          history.push(to, state);
        } else {
          history.push(simplifyHistoryTo(to));
        }
      },
      replace: (to: To, state?: unknown) => {
        if (state != null) {
          history.replace(to, state);
        } else {
          history.replace(simplifyHistoryTo(to));
        }
      },
    };
  }, [history]);

  return (
    <Router location={location} navigator={navigator}>
      {children}
    </Router>
  );
}

const productRouteRegex = /^\/:product\((?<products>[a-z]+(?:\|[a-z]+)*)\)(?<subpath>\/.*)?$/;

function updateRouteChildren(children: React.ReactNode): React.ReactNode {
  const array = React.Children.map(children, (child) => {
    if (Array.isArray(child)) {
      return updateRouteChildren(child);
    }
    if (!React.isValidElement<RouteProps>(child)) {
      return child;
    }
    if (child.type === React.Fragment) {
      return updateRouteChildren(child.props.children);
    }
    if (child.type !== Route) {
      return child;
    }

    const { element, path, Component, ...otherChildProps } = child.props;
    const pathMatch = path?.match(productRouteRegex);
    const products = pathMatch?.groups?.products?.split('|');
    if (!pathMatch || !products) {
      return child;
    }

    return (
      <>
        {(products as Products[]).map((product) => (
          <Route
            key={product}
            path={`/${product}${pathMatch.groups?.subpath ?? ''}`}
            element={
              Component != null || element != null ? (
                <ProductProvider product={product}>
                  {Component != null ? <Component /> : element}
                </ProductProvider>
              ) : undefined
            }
            {...otherChildProps}
          />
        ))}
      </>
    );
  });

  if (array == null || array.length === 0) {
    return null;
  }
  if (array.length === 1) {
    return <>{array[0]}</>;
  }
  return React.createElement(React.Fragment, null, array);
}

export function RoutesWithProduct({ children, ...otherRoutesProps }: RoutesProps): JSX.Element {
  const childArray = useMemo(() => updateRouteChildren(children), [children]);

  return <Routes {...otherRoutesProps}>{childArray}</Routes>;
}

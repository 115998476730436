import React, { useCallback, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Display,
  FormChangeEvent,
  Header,
  HelpTextAnimated,
  LinkButton,
  QueryComponent,
  Size,
  SplashText,
  Style,
  TextArea,
  messagingI18n,
} from '@pointdotcom/pds';
import BrowseFilesButton from 'components/BrowseFilesButton';
import DocumentHelpModal from 'components/DocumentHelpModal';
import FileUploadArea from 'components/FileUploadArea';
import { UploadIcon } from 'components/FileUploadArea/icons';
import HaveAQuestionModal from 'components/HaveAQuestionModal';
import UploadedFiles from 'components/UploadedFiles';
import { supportedContentTypes } from 'containers/hooks/useTaskFileUpload';
import useTaskFiles, { FileItemKey } from 'containers/hooks/useTaskFiles/useTaskFiles';
import { DashboardTaskPageContentProps } from 'containers/tasks/DashboardTaskPage/DashboardTaskPageContentProps';
import { useZodStorageItem } from 'hooks/useLocalStorageItem';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { usePostTaskMutation, useTaskOpenedMutation } from 'services/api/homeownerApi';
import {
  TaskStatus,
  TaskSubmissionError,
  TaskType,
  VerificationStatus,
} from 'services/apiTypes/taskTypes';
import { confirmDeleteFile } from '../utils';
import VerificationError from './VerificationError';
import i18n from './i18n';
import { CheckmarkIcon, PaperclipIcon } from './icons';
import * as styles from './styles';

type AttachDocumentsButtonProps = Pick<
  React.ComponentProps<typeof BrowseFilesButton>,
  'onAddFiles'
>;

function AttachDocumentsButton({ onAddFiles }: AttachDocumentsButtonProps) {
  return (
    <BrowseFilesButton block styleType={Style.Tertiary} onAddFiles={onAddFiles}>
      <PaperclipIcon />
      {/* magic number below needed to keep button text from wrapping */}
      <QueryComponent queriedComponent={i18n.attachDocumentsOptional} breakpoint="1042px">
        {i18n.attachSupportingDocumentsOptional}
      </QueryComponent>
    </BrowseFilesButton>
  );
}

type GenericFollowupPageContentProps = DashboardTaskPageContentProps<
  TaskType.GenericTextFollowupQuestion | TaskType.GenericDocumentFollowupQuestion
>;

const seenErrorsLocalStorageKey = 'seenTaskSubmissionErrors';
const seenErrorsSchema = z.array(z.object({ taskId: z.string(), errorId: z.string() }));

export default function GenericFollowupPageContent({
  task,
  dashboard,
}: GenericFollowupPageContentProps) {
  // Notify that this task has been opened/viewed by user
  const [taskOpened] = useTaskOpenedMutation();
  useEffect(() => {
    taskOpened({ id: task.id });
  }, [task.id, taskOpened]);

  const [responseText, setResponseText] = useState('');
  const [haveQuestionModalOpen, setHaveQuestionModalOpen] = useState(false);
  const [documentHelpModalIsOpen, setDocumentHelpModalIsOpen] = useState(false);
  const hasVerificationError =
    task.type === TaskType.GenericDocumentFollowupQuestion &&
    task.detail.verification?.status === VerificationStatus.Invalid;
  const taskSubmitFileVerificationFlagEnabled =
    useFeatureFlag(FeatureFlag.TaskSubmitFileVerification) ?? false;
  const hideNotes =
    task.type === TaskType.GenericDocumentFollowupQuestion && !!task.detail.hideNotes;

  const [seenErrors, setSeenErrors] = useZodStorageItem(
    seenErrorsLocalStorageKey,
    seenErrorsSchema
  );
  const [newSubmissionErrors, setNewSubmissionErrors] = useState<TaskSubmissionError[]>([]);

  useEffect(() => {
    const thisTaskSeenErrors = seenErrors?.filter((error) => error.taskId === task.id);
    const unseenErrors = task.submissionErrors?.filter(
      (error) => !thisTaskSeenErrors?.some(({ errorId }) => error.id === errorId)
    );
    if (unseenErrors != null && unseenErrors.length > 0) {
      setSeenErrors((old) => [
        ...(old ?? []),
        ...unseenErrors.map((error) => ({ errorId: error.id, taskId: task.id })),
      ]);
      setNewSubmissionErrors((old) => [...old, ...unseenErrors]);
    }
  }, [seenErrors, setSeenErrors, task.id, task.submissionErrors]);

  const verificationErrorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hasVerificationError) {
      verificationErrorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [hasVerificationError]);

  const handleResponseTextChange: FormChangeEvent = (e, { value }) => {
    setResponseText(value);
  };

  const {
    handleAddFiles,
    deleteFile,
    removeFileErrorKey,
    fileItems,
    haveUploadsInProgress,
    haveErrorsInUploads,
    haveUploadsToSubmit,
  } = useTaskFiles({ taskFiles: task.files, taskId: task.id });

  const readyToSubmit =
    !haveUploadsInProgress &&
    !haveErrorsInUploads &&
    ((task.type === TaskType.GenericDocumentFollowupQuestion && haveUploadsToSubmit) ||
      (task.type === TaskType.GenericTextFollowupQuestion && responseText.length > 0));

  const [postTask, { isLoading: postTaskInProgress, isError }] = usePostTaskMutation();

  const submissionInProgress =
    postTaskInProgress || task.status === TaskStatus.SubmissionInProgress;

  const [skippingVerification, setSkippingVerification] = useState(false);
  const verifyOnSubmit =
    taskSubmitFileVerificationFlagEnabled &&
    !(submissionInProgress && skippingVerification) &&
    task.type === TaskType.GenericDocumentFollowupQuestion &&
    task.detail.verifiable;

  const submitButtonLabel: string = (() => {
    if (verifyOnSubmit) {
      return submissionInProgress ? i18n.verifyingYourDocument : i18n.verify;
    } else {
      return submissionInProgress ? i18n.submitting : i18n.submit;
    }
  })();

  const canSubmit = readyToSubmit && !submissionInProgress && task.status !== TaskStatus.Submitted;
  const handleSubmit = async () => {
    if (!canSubmit) {
      return;
    }
    setSkippingVerification(false);
    setNewSubmissionErrors([]);
    await postTask({
      id: task.id,
      data: {
        notes: hideNotes ? undefined : responseText,
        skipVerification: !taskSubmitFileVerificationFlagEnabled,
      },
    });
  };

  const handleClickManualReview = async () => {
    if (!canSubmit) {
      return;
    }
    setSkippingVerification(true);
    setNewSubmissionErrors([]);
    await postTask({
      id: task.id,
      data: {
        notes: responseText,
        skipVerification: true,
      },
    });
  };

  const handleDeleteFile = useCallback(
    async (fileKey: FileItemKey) => {
      const confirm = confirmDeleteFile();
      removeFileErrorKey(fileKey);
      if (confirm) {
        try {
          await deleteFile(fileKey);
        } catch (e) {
          // handled via deleteFileError
        }
      }
    },
    [deleteFile, removeFileErrorKey]
  );

  return (
    <styles.DashboardPageStyle>
      <HaveAQuestionModal
        isOpen={haveQuestionModalOpen}
        accountManager={dashboard.accountManager}
        homeowner={dashboard.getContact()}
        onModalClose={() => setHaveQuestionModalOpen(false)}
      />
      <styles.HeaderSectionStyle>
        <Header styleSize={Size.Large} noMargin>
          {task.title ?? task.taskDescription}
        </Header>
        <SplashText>{task.detail.requestText}</SplashText>
      </styles.HeaderSectionStyle>
      <styles.FollowupQuestionCardStyle
        hasVerificationError={hasVerificationError}
        mobileCollapse
        taskType={task.type}
        hasUploadedFiles={fileItems.length > 0}
      >
        {task.type === TaskType.GenericDocumentFollowupQuestion ? (
          <styles.FileUploadContainerStyle>
            {hasVerificationError && (
              <VerificationError
                ref={verificationErrorRef}
                problems={task.detail.verification?.problems ?? []}
                recommendations={task.detail.verification?.recommendations ?? []}
                onManualReview={handleClickManualReview}
                submitting={submissionInProgress && skippingVerification}
                submitDisabled={!canSubmit}
              />
            )}

            <QueryComponent
              display={Display.Contents}
              queriedComponent={
                <BrowseFilesButton onAddFiles={handleAddFiles} styleType={Style.Dark}>
                  <UploadIcon />
                  {i18n.uploadDocument}
                </BrowseFilesButton>
              }
            >
              <FileUploadArea onAddFiles={handleAddFiles} contentTypes={supportedContentTypes} />
            </QueryComponent>
          </styles.FileUploadContainerStyle>
        ) : (
          <styles.NotesContainerStyle>
            <TextArea
              placeholder={i18n.responsePlaceholder}
              value={responseText}
              onChange={handleResponseTextChange}
              rows={6}
              noMargin
            />
          </styles.NotesContainerStyle>
        )}
        {fileItems.length > 0 && (
          <>
            <UploadedFiles taskId={task.id} fileItems={fileItems} onDeleteFile={handleDeleteFile} />
            {task.type === TaskType.GenericDocumentFollowupQuestion && !hideNotes && (
              <styles.NotesContainerStyle>
                <TextArea
                  placeholder={i18n.notesAboutAttachments}
                  value={responseText}
                  onChange={handleResponseTextChange}
                  rows={3}
                  noMargin
                />
              </styles.NotesContainerStyle>
            )}
          </>
        )}
        {task.type === TaskType.GenericDocumentFollowupQuestion && task.detail.help != null && (
          <styles.HelpWithThisDocContainerStyle>
            <LinkButton onClick={() => setDocumentHelpModalIsOpen(true)}>
              {i18n.helpWithThisDocument}
            </LinkButton>
          </styles.HelpWithThisDocContainerStyle>
        )}
        {task.type === TaskType.GenericTextFollowupQuestion && (
          <styles.AttachButtonContainerStyle>
            <AttachDocumentsButton onAddFiles={handleAddFiles} />
          </styles.AttachButtonContainerStyle>
        )}
        {(task.type !== TaskType.GenericDocumentFollowupQuestion || fileItems.length > 0) && (
          <styles.CTAContainerStyle>
            <Button
              block
              onClick={handleSubmit}
              loading={submissionInProgress}
              loadingShowText
              disabled={!canSubmit}
            >
              {submitButtonLabel}
            </Button>
          </styles.CTAContainerStyle>
        )}
        {(isError || newSubmissionErrors.length > 0) && (
          <styles.ErrorMessageContainerStyle>
            <HelpTextAnimated
              styleAlign={DirectionAndPlacement.Center}
              noMargin
              show
              styleType={Style.Error}
            >
              {messagingI18n.errors.unknownError}
            </HelpTextAnimated>
          </styles.ErrorMessageContainerStyle>
        )}
      </styles.FollowupQuestionCardStyle>
      {task.type === TaskType.GenericDocumentFollowupQuestion && (
        <Container>
          <styles.DocumentRequirementsContainerStyle>
            <Header>{i18n.documentRequirements}</Header>
            <styles.DocumentRequirementsListStyle>
              {i18n.documentRequirementsList.map((requirement) => (
                <styles.DocumentRequirementStyle key={requirement}>
                  <CheckmarkIcon />
                  <div>{requirement}</div>
                </styles.DocumentRequirementStyle>
              ))}
            </styles.DocumentRequirementsListStyle>
          </styles.DocumentRequirementsContainerStyle>
        </Container>
      )}
      <styles.FooterSectionStyle>
        <Container>
          <Button styleType={Style.Tertiary} onClick={() => setHaveQuestionModalOpen(true)}>
            {i18n.haveAQuestion}
          </Button>
        </Container>
      </styles.FooterSectionStyle>
      {task.type === TaskType.GenericDocumentFollowupQuestion && (
        <DocumentHelpModal
          docName={task.title ?? task.taskDescription}
          help={task.detail.help}
          isOpen={documentHelpModalIsOpen}
          onModalClose={() => setDocumentHelpModalIsOpen(false)}
        />
      )}
    </styles.DashboardPageStyle>
  );
}

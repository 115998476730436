import { defineFeatureFlag } from './featureFlagDefintionHelpers';

// Set in console like so - document.cookie = 'm2_dashboard=true';

export const posthogFeatureFlagDefinitions = {
  _test_flag: defineFeatureFlag('__test_flag__', ['first_value', 'other_value']),
  ApplicationTaskCompleteModal: defineFeatureFlag('application_task_complete_modal', [
    'control',
    'modal',
  ]),
  PostAppDocUploadFollowup: defineFeatureFlag('post_app_doc_upload_followup', [false, true]),
  PostFundingCalc: defineFeatureFlag('post_funding_calc', ['disabled', 'enabled']),
  PrequalContact: defineFeatureFlag('cr10b-pqflow-contact', ['control', 'test']),
  ApplicationHEIAmount: defineFeatureFlag('cr15b-app-offer-amount', ['control', 'test']),
  ApplicationCompleteAnimation: defineFeatureFlag('cr18-app-completion-animation', [
    'control',
    'test',
  ]),
  SelfServeOfferAmount: defineFeatureFlag('cr16-self-serve-offer-amt', ['control', 'test']),
  DashboardAmChat: defineFeatureFlag('dashboard-am-chat', ['control', 'test']),
  DebtPayoffOption: defineFeatureFlag('debt-payoff-option', ['disabled', 'enabled']),
  ShowFileUploadPreviews: defineFeatureFlag('show-file-upload-previews', [false, true]),
  TaskSubmitFileVerification: defineFeatureFlag('task-submit-file-verification', [false, true]),
  PricingCarousel: defineFeatureFlag('cr24-pqoffer-carousel', ['control', 'test']),
  TenYearEstimator: defineFeatureFlag('cr30-default-cost-estimator', ['control', 'test']),
};

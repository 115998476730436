import * as React from 'react';
import { useRef, useState } from 'react';
import {
  Container,
  Header,
  LinkButton,
  Size,
  SplashText,
  Style,
  useScrollSpy,
} from '@pointdotcom/pds';
import RangeBuyBackTime from 'components/RangeBuyBackTime';
import RangeEstimatePrepayment from 'components/RangeEstimatePrepayment';
import SectionShadow from 'components/SectionShadow';
import { DEFAULT_BUY_BACK_TIME_IN_YEARS } from 'containers/EstimatorPage';
import { FixedControls } from 'containers/EstimatorPage/PrepaymentAndBuyBackSection';
import ScenariosTableAndCarousel from 'containers/EstimatorPage/ScenariosTableAndCarousel';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { Products } from 'store/constants';
import { PricingRangeType } from 'store/estimates';
import { BorderedSectionStyle, HeroContainerStyle } from 'styles';
import i18n from './i18n';
import {
  CurrentOfferContainerStyle,
  DividerStyle,
  GridStyle,
  HeroTextContainerStyle,
  LargeOfferAmountStyle,
  RangeSliderStyle,
  RangesStyle,
  ScenariosSectionPaddedStyle,
  ScenariosSectionStyle,
  ScenariosTableStyle,
  SliderNumberStyle,
} from './styles';

interface HeiOfferEstimatorPageScenariosSectionProps {
  estimate: null | OfferEstimateModel;
  pricingRange: null | PricingRangeType;
  isHero?: boolean;
  bordered?: boolean;
  header?: null | React.ReactNode;
  numberedSliders?: boolean;
  longDivider?: boolean;
  showCurrentOffer?: boolean;
  onRequestDifferentAmount?: null | (() => unknown);
}

export default function HeiOfferEstimatorPageScenariosSection({
  estimate,
  pricingRange,
  isHero = false,
  bordered = false,
  header = null,
  numberedSliders: numberedSlidersStyle = false,
  longDivider = false,
  showCurrentOffer = false,
  onRequestDifferentAmount = null,
}: HeiOfferEstimatorPageScenariosSectionProps): null | JSX.Element {
  // CR30: Test initial value of buyback time
  const tenYearEstimatorFlagEnabled = useFeatureFlag(FeatureFlag.TenYearEstimator) === 'test';
  const defaultBuyBackTime = tenYearEstimatorFlagEnabled ? 10 : DEFAULT_BUY_BACK_TIME_IN_YEARS;
  const [buyBackTimeFromState, setBuyBackTime] = useState<number | string | null>(null);
  const buyBackTime = buyBackTimeFromState ?? defaultBuyBackTime;

  const spyOnControlsRef = useRef(null);
  const spyOnTableRef = useRef(null);

  const { scrollIsPastSpyBottom: scrollIsPastControlsSpyBottom } = useScrollSpy({
    spyOn: spyOnControlsRef,
  });

  const { scrollIsPastSpyBottom: scrollIsPastTableSpyBottom } = useScrollSpy({
    spyOn: spyOnTableRef,
    bufferBottomRatio: -0.3, // hide a little before its at the absolute bottom
  });

  const showFloatingControls = scrollIsPastControlsSpyBottom && !scrollIsPastTableSpyBottom;

  if (estimate == null) {
    return null;
  }

  const product = estimate.productType ?? Products.HEI;

  const showPricingRangeSlider =
    pricingRange != null && pricingRange?.hasMinEstimatesForUIControl();
  const numberedSliders = numberedSlidersStyle && showPricingRangeSlider;

  let SectionStyle = ScenariosSectionPaddedStyle;
  if (bordered) {
    SectionStyle = BorderedSectionStyle;
  }
  if (isHero) {
    SectionStyle = HeroContainerStyle;
  }

  return (
    <>
      <ScenariosSectionStyle ref={spyOnControlsRef} as={SectionStyle} bordered={bordered}>
        <Container>
          <GridStyle numberedSlidersStyle={numberedSlidersStyle} longDivider={longDivider}>
            <HeroTextContainerStyle>
              {header ?? (
                <>
                  <Header styleSize={Size.Large}>{i18n.yourCostEstimator}</Header>
                  <SplashText noMargin>{i18n.description}</SplashText>
                </>
              )}
            </HeroTextContainerStyle>
            <DividerStyle />
            <RangesStyle numberedSlidersStyle={numberedSlidersStyle}>
              {showPricingRangeSlider && (
                <>
                  <RangeSliderStyle numberedSliders={numberedSliders}>
                    {numberedSliders && <SliderNumberStyle />}
                    <Header styleSize={Size.Small}>{i18n.howMuchMoney}</Header>
                    <RangeEstimatePrepayment
                      pricingRange={pricingRange}
                      styleType={Style.Default}
                      dropdownButtonProps={{
                        styleSize: Size.Default,
                        styleType: Style.Dark,
                      }}
                      rangeProps={{
                        styleSize: Size.Default,
                        showMinMaxLabels: false,
                      }}
                    />
                  </RangeSliderStyle>
                  {numberedSlidersStyle && <DividerStyle />}
                </>
              )}
              {!showPricingRangeSlider && showCurrentOffer && (
                <>
                  <CurrentOfferContainerStyle>
                    {numberedSliders && <SliderNumberStyle />}
                    <Header styleSize={Size.Small}>{i18n.yourCurrentOffer}</Header>
                    <LargeOfferAmountStyle>
                      {estimate.getFormattedMaxOptionAmount()}
                    </LargeOfferAmountStyle>
                    {onRequestDifferentAmount != null && (
                      <LinkButton onClick={onRequestDifferentAmount}>
                        {i18n.requestDifferentAmount}
                      </LinkButton>
                    )}
                  </CurrentOfferContainerStyle>
                  {numberedSlidersStyle && <DividerStyle />}
                </>
              )}
              <RangeSliderStyle numberedSliders={numberedSliders}>
                {numberedSliders && <SliderNumberStyle />}
                <Header styleSize={Size.Small}>{i18n.whenDoYouThink}</Header>
                <RangeBuyBackTime
                  estimate={estimate}
                  buyBackTime={buyBackTime}
                  setBuyBackTime={setBuyBackTime}
                  rangeProps={{
                    styleSize: Size.Default,
                    showMinMaxLabels: false,
                  }}
                />
              </RangeSliderStyle>
            </RangesStyle>
          </GridStyle>
        </Container>
      </ScenariosSectionStyle>
      <FixedControls
        product={product}
        pricingRange={pricingRange}
        getBuyBackTime={() => buyBackTime}
        setBuyBackTime={setBuyBackTime}
        estimate={estimate}
        scrollShown={showFloatingControls}
      />
      <ScenariosTableStyle ref={spyOnTableRef}>
        <SectionShadow />
        <ScenariosTableAndCarousel
          estimate={estimate}
          product={product}
          getBuyBackTime={() => buyBackTime}
        />
      </ScenariosTableStyle>
    </>
  );
}
